import React, { useState, useEffect } from "react";
import Container from "./Container";
import Stack from "./Stack";
import Logo from "./Logo";
import { Link } from "gatsby";

const Nav = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={`nav ${scrolled && "scrolled "} ${open && " open"}`} >
      <span>Ben Holme</span>
      <span style={{"opacity": .5}}>Portfolio 2023</span>
    </nav>
  );
};

export default Nav;
