import React, { useEffect, useState } from "react";
const Gig = ({ children, tall = true, background, year, role, logo, desc, image, light = false, offset = [-4, -9, -12], maxWidth = 117 }) => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); 
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={`gig ${light && "light"}`}
      style={{
        overflow: "hidden",
        height: tall ? "100vh" : "40vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        paddingLeft: "70px",
        boxSizing: "border-box",
        background: `${background}`,
        backgroundSize: "cover", 
        backgroundPosition: "center",
        borderBottom: "solid rgba(0,0,0,.1) 1px"
      }}
    >
      <div className="inner">
        <div className="image">
          <img style={{
            maxWidth: maxWidth + "%",
            width: maxWidth + "%",
            marginTop: offset[0] + "%",
            marginLeft: offset[1] + "%",
            marginBottom: offset[2] + "%"
          }} src={image} />
        </div> 
        <div className="text"
          style={{
            //opacity: 1 - scroll * (tall ? 0.001 : 0.005),
          }}
        >
          {logo}
          <div class="desc">
            <p>{desc}</p>
            <p style={{opacity: .5}}>{role}</p>
            <p style={{opacity: .5}}>{year}</p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Gig;
