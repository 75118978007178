import React from "react";
const Logos = () => {
  return (
    <section className="logos">
      <span>Other projects 2008-2022</span>
      <img src="/logos.png" />
    </section>
  );
};

export default Logos;
