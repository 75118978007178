import React, { useEffect, useState } from "react";
const Intro = ({ children, tall }) => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
    className="intro"
      style={{
        height: tall ? "100vh" : "40vh",
        display: "flex",
        alignItems: "flex-end",
        position: "relative",
        overflow: "hidden",
        padding: "10vw",
        boxSizing: "border-box"
      }}
    >
      <h1
        style={{
          fontSize: "4vw",
          zIndex: 1,
          transform: `translateY(${scroll * 0.5}px)`,
          opacity: 1 - scroll * (tall ? 0.001 : 0.005),
        }}
      >
        {children}
      </h1>
    </div>
  );
};

export default Intro;
