import React from "react";
import Nav from "./Nav";
import Section from "./Section";
import Stack from "./Stack";
import {Link} from "gatsby";
import "./layout.css";
import Logo from "./Logo";

const Layout = ({ children }) => {
  return (
    <main >
      <Nav />
      {children}
      <footer>
        Benjamin Holme<br/>
        benjamin.holme0@gmail.com<br/>
        0736120783
      </footer>
    </main>
  ); 
};

export default Layout;
